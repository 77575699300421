import React from 'react'
import './services.css'
import { BiCheck } from 'react-icons/bi'

const Services = () => {
  return (
    <section id="services">
      <h5>What I Offer</h5>
      <h2>Services</h2>
      
      <div className="container services__container">
        <article className="service">
          <div className="service__head">
            <h3>UI/UX Design</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>UI DESIGN</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>UI DEVELOPMENT</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>UI GUIDELINES</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>DESIGN SYSTEM</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>UX DESIGN</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>USER RESEARCH</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>UX CONSULTANCY</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>UX WRITING</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>ANIMATIONS</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>ICONOGRAPHY</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>ILLUSTRATIONS</p>
            </li>
          </ul>
        </article>
        {/* End of UX Design */}
        <article className="service">
          <div className="service__head">
            <h3>Web Development</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>SEARCH ENGINE OPTIMIZATION</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>SOCIAL MEDIA MARKETING</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>GOOGLE PPC</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>PAID PROMOTIONS</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>CUSTOM UI</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>FULL ADMIN CONTROL</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>THIRD PARTY INTEGRATIONS</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>MAINTENANCE AND SUPPORT</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>UNIQUE DESIGNS</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>LATEST TECHNOLOGY</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>CUSTOM DEVELOPMENT</p>
            </li>
          </ul>
        </article>
        {/* End of UX Design */}
        <article className="service">
          <div className="service__head">
            <h3>Content Creation</h3>
          </div>
          <ul className='service__list'>
            <li>
              <BiCheck className='service__list-icon' />
              <p>WEBSITE CONTENT</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>BLOGS & SEO ARTICLES</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>PRODUCT DESCRIPTIONS</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>BROCHURE CONTENT</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>CASE STUDY CONTENT</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>SCRIPT WRITING</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>GRAPHIC DESIGN SERVICES</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>VIDEO MARKETING SERVICES</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>END-TO-END CONTENT CREATION</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>E-BOOK MARKETING SERVICES</p>
            </li>
            <li>
              <BiCheck className='service__list-icon' />
              <p>WHITE PAPER MARKETING SERVICES</p>
            </li>
          </ul>
        </article>
        {/* End of UX Design */}
      </div>
    </section>
  )
}

export default Services