import React, { useRef } from 'react';
import './contact.css'  
import { MdOutlineEmail } from 'react-icons/md'
import { RiMessengerLine } from 'react-icons/ri'
import { BsWhatsapp } from 'react-icons/bs'

import emailjs, { send } from 'emailjs-com'

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_lk59gof', 'template_x9tutcf', form.current, 'QUOfOXEY5DF06K_tU')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset();
  };

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className="contact__option">
            <MdOutlineEmail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>liyakatshaikh07@gmail.com</h5>
            <a href="mailto:liyakatshaikh07@gmail.com">Send a message</a>
          </article>
          <article className="contact__option">
            <RiMessengerLine className='contact__option-icon' />
            <h4>Messenger</h4>
            <h5>M4YBESHAH</h5>
            <a href="https://m.me/M4YBESHAH" target={"_blank"} >Send a message</a>
          </article>
          <article className="contact__option">
            <BsWhatsapp className='contact__option-icon' />
            <h4>Whatsapp</h4>
            <h5>+91 7709337341</h5>
            <a href="https://api.whatsapp.com/send?phone=917709337341" target={"_blank"} >Send a message</a>
          </article>
        </div>
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="name" placeholder="Alice Jackson" required />
          <input type="email" name="email" placeholder="alice@gmail.com" required />
          <textarea name="message" rows="7" placeholder="Your Message" required />
          <button type="submit" className='btn btn-primary'> Send Message </button>
        </form>
      </div>
    </section>
  )
}

export default Contact