import React from 'react'
import './testimonials.css'
import AVTR1 from '../../assets/images/avatar1.png'
// import Swiper core and required modules
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

const data = [
  {
    avatar: AVTR1,
    name: 'Shoeb Baghwan',
    review: "He provides me the Services in Cheap and Affordable pricing to grow my business, Thank you Liyakat."
  },
  {
    avatar: AVTR1,
    name: 'R. K. Mehta',
    review: "Well, as a brand new startup. I tried MaybeSHAH's Services and It was Great! Thank you MaybeSHAH."
  },
  {
    avatar: AVTR1,
    name: 'Aatish Desai',
    review: "He provides me unique designs to my business that suits me best. It looks very Modern and Impressive. I am very happy!"
  },
  
]

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Review from clients</h5>
      <h2>Testimonials</h2>

      <Swiper className="container testimonials__container"
      modules={[Pagination]}
      spaceBetween={10}
      slidesPerView={1}
      pagination={{ clickable: true }}
      >
        {
          data.map(({avatar, name, review}, index) => {
            return(
              <SwiperSlide className="testimonial">
                <div className="client__avatar">
                  <img src={avatar} />
                  </div>
                  <h5 className='client__name'>{ name }</h5>
                  <small className='client__review'>{ review }</small>
              </SwiperSlide>
            )
          })
        } 
      </Swiper>
    </section>
  )
}

export default Testimonials