import React from 'react'
import { BsLinkedin, BsGithub, BsInstagram } from 'react-icons/bs'

const HeaderSocials = () => {
  return (
    <div className="header__socials">
        <a href="https://www.linkedin.com/in/liyakat-shaikh-120219136/" target="_blank"><BsLinkedin /></a>
        <a href="https://github.com/MaybeSHAH" target="_blank"><BsGithub /></a>
        <a href="https://www.instagram.com/maybe_shah/" target="_blank"><BsInstagram /></a>
    </div>
  )
}

export default HeaderSocials